<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol class="card-header-with-button">Sites</CCol>
            <CCol class="text-right"
              ><CButton
                color="success"
                @click="addSiteClick"
                v-if="permissionsData.edit_site"
                >Add Site</CButton
              ></CCol
            >
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            v-if="showTable"
            hover
            striped
            :items="sitesData"
            :fields="fields"
            :items-per-page="15"
            clickable-rows
            :active-page="activePage"
            @row-clicked="rowClicked"
            :pagination="{ doubleArrows: false, align: 'center' }"
            @page-change="pageChange"
          >
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import store from "../../store";
import constants from "../../data/Constants.js";
import DynamoDBService from "../../services/dynamodbservice.js";
export default {
  name: "Sites",
  data() {
    return {
      permissionsData: {},
      credentials: null,
      showTable: false,
      sitesData: [],
      fields: [
        { key: "site_name", _classes: "font-weight-bold" },
        { key: "site_location" },
        { key: "site_building" },
      ],
      activePage: 1,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  mounted: function() {
    this.permissionsData = store.state.permissions;
  },
  created() {
    if (this.credentials) {
      // have credentials
      console.log("in created(), have credentials");
      this.getSiteItems();
    } else {
      // no credentials
      console.log("in created(), getting credentials...");
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getCredentials().then(
        (data) => {
          this.credentials = data;
          this.getSiteItems();
        },
        (error) => {
          console.log(error);
          this.getSiteItems();
        }
      );
    }
  },
  methods: {
    rowClicked(item, index) {
      this.$router.push({ path: `sites/edit`, query: { selectedData: item } });
    },
    addSiteClick() {
      this.$router.push({ path: `sites/1` });
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    getSiteItems() {
      var that = this;
      var params = {
        TableName: constants.SITES_TABLE_NAME,
      };
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getAllItems(params).then(
        (data) => {
          if (data != undefined || data != null || data.Count > 0) {
            that.sitesData = [];

            var role = store.state.currentUser.jogg_role;
            if (role == "admin") {
              that.sitesData = data.Items;
            } else {
              var currentUserSites = store.state.currentUser.sites;
              var splitSites = currentUserSites.split(",");
              data.Items.forEach((element) => {
                splitSites.forEach((element1) => {
                  if (element1 == element.site_id) {
                    that.sitesData.push(element);
                  }
                });
              });
            }

            that.showTable = false;
            that.$nextTick().then(function() {
              that.showTable = true;
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
